import React, { FC } from "react"
import { Card as CustomCard, CardProps } from "antd"
import { Spin } from "lib"
interface CustomProps {
  isLoading?: boolean
}

interface ICard extends CustomProps, CardProps {}

const Card: FC<ICard> = ({ children, isLoading = false, ...rest }) => {
  return (
    <CustomCard {...rest}>
      <Spin spinning={isLoading}>{children}</Spin>
    </CustomCard>
  )
}

export default Card
