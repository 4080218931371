import React from "react";
import { message, Modal } from "antd";
import { Space } from "lib";
import { navigate } from "gatsby";
import { QuestionType, FileResponse } from "@api";
import moment from "moment";
import { login, mgr } from "@services/auth";

export const showError = (error: any): void => {
  console.log(error);
  try {
    switch (error.status) {
      case 401:
        Modal.info({
          title: "閒置過久,連線階段已過期,請重新登入",
          centered: true,
          onOk: () =>
            mgr
              ?.signinSilent()
              .then(() => window.location.reload())
              .catch(() => login()),
        });
        return;
      case 404:
        message.warning("找不到相關紀錄");
        return;
      case 403:
        message.warning("沒有相關權限");
        return;
      case 500:
        message.error("系統發生錯誤,請稍後再試");
        return;
      case 503:
        message.warning("系統正在進行維護,請稍後再試");
        return;
      default:
        break;
    }
    if (!error?.response) return;
    const response = JSON.parse(error.response);
    const errList = response.errors || {};
    message.warning(
      <Space>
        {Object.values(errList).map((msg, i) => `${i + 1} :${msg}`)}
      </Space>
    );
  } catch (err) {
    message.error("系統發生錯誤");
  }
};

export const handlePageError = (error: any): void => {
  console.log(error);
  try {
    switch (error.status) {
      case 404:
        navigate("/404/", { replace: true });
        return;
      case 403:
        navigate("/403/", { replace: true });
        return;
      default:
        message.error("系統發生錯誤");
        window.history.back();
        break;
    }
  } catch (err) {
    message.error("系統發生錯誤");
  }
};

export const findAnswer: any = (type: QuestionType, answers: any) => {
  switch (type) {
    case QuestionType.Text:
      return answers?.textAnswer;
    case QuestionType.SingleRadio:
    case QuestionType.SingleDropDown:
      return answers?.questionChoiceId;
    case QuestionType.Scale:
      return answers?.ratingScalePointId;
    default:
      return null;
  }
};

export const translateAnswer: any = (type: QuestionType, value: any) => {
  switch (type) {
    case QuestionType.Text:
      return { textAnswer: value };
    case QuestionType.SingleRadio:
    case QuestionType.SingleDropDown:
      return { questionChoiceId: value };
    case QuestionType.Scale:
      return { ratingScalePointId: value };
    default:
      return null;
  }
};

export const formatDate = (date: string, format = process.env.DATE_FORMAT) =>
  date ? moment(date).format(format) : "-";

export const Download = (f: FileResponse) => {
  const url = URL.createObjectURL(new Blob([f.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", f.fileName ?? "");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
