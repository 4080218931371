import React, { useEffect, useState, useContext } from "react";
import { PageProps } from "gatsby";
import { login, isLoggedIn, getProfile } from "./auth";
import { ClientUsersClient } from "@api";
import { Loading } from "lib";
import { showError } from "@action";
import { navigate } from "gatsby";
import { Store } from "@store";

const PrivateRoute = ({ children, path }: PageProps) => {
  const isAuth = isLoggedIn(path);
  const { setLang, setClientUserInfo } = useContext(Store);

  if (!isAuth) {
    login();
    return <Loading />;
  }

  const [isLoading, setIsLoading] = useState(true);

  const needCheckUser = ![
    "/logout-callback/",
    "/login-callback/",
    "/silent-callback/",
    "/welcome/",
    "/register/:passphrase",
  ].includes(path);

  useEffect(() => {
    const checkRedirectPage = async () => {
      const user = getProfile();
      const client = new ClientUsersClient();
      const result = await client.getCurrentClientUser().catch((err) => {
        showError(err);
        return null;
      });
      const needChangePassword =
        !result?.haveChangePassword && result?.clientGroupCanCustomizePassword;
      const needEnterDetail =
        !result?.haveEnterDetail &&
        (result?.clientGroupCanCustomizeFullName ||
          result?.clientGroupCanCustomizeEmail ||
          result?.clientGroupCanCustomizePreferredLanguage);

      setLang(user.PreferredLanguageId || "en");

      setClientUserInfo(result);

      if (needChangePassword || needEnterDetail) {
        navigate("/welcome/", {
          replace: true,
          state: {
            redirectUrl: path,
          },
        });
      } else {
        setIsLoading(false);
      }
    };

    if (isAuth && needCheckUser) checkRedirectPage();
  }, []);

  return isLoading && needCheckUser ? <Loading /> : children;
};

export default PrivateRoute;
