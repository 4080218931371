import React from "react"
import { Button as CustomButton, ButtonProps } from "antd"

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <CustomButton
      shape={children ? "round" : undefined}
      type="primary"
      {...rest}
    >
      {children}
    </CustomButton>
  )
}

export default Button
