import "antd/lib/style/themes/default.less"
import "antd/dist/antd.less" // Import Ant Design styles by less entry
import "./src/theme/global.less"
import React from "react"
import { StoreProvider } from "./src/store"
import PrivateRoute from "./src/services/PrivateRoute"
import { clearStale } from "./src/services/auth"
export const wrapRootElement = ({ element }) => {
  return <StoreProvider>{element}</StoreProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return <PrivateRoute {...props}>{element}</PrivateRoute>
}

export const onClientEntry = () => {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode
  if (isIE) {
    alert(
      "此系統並不支援 Internet Explorer\n請使用Google Chrome 或 Microsoft Edge 瀏覽器以獲得最佳瀏覽體驗\n\nThis system does not support Internet Explorer\nPlease use Google Chrome or Microsoft Edge for the best experience"
    )
    window.open("", "_self", "")
    window.close()
  }
  clearStale()
}
