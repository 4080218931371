import React, { ReactNode } from "react";
import {
  Form,
  FormItemProps,
  Input,
  Select,
  Radio,
  Row,
  Col,
  Grid,
} from "antd";
import { selectProps } from "@objects";
import { Space } from "lib";
import { QuestionType, IClientUserFormQuestionDto } from "@api";
import "./styles.css";

interface IItem {
  options?: {
    label: string | ReactNode;
    value: string | number;
    disabled: boolean;
  }[];
  inputProp?: any;
  onInput?: (e: any) => void;
  isLoading: boolean;
  currentAnswer: any;
}

interface IQuestionSlot
  extends IItem,
    IClientUserFormQuestionDto,
    FormItemProps<any> {}

const QuestionSlot: React.FC<IQuestionSlot> = ({
  currentAnswer,
  isOptional = true,
  questionType = QuestionType.Text,
  isLoading,
  rules = [],
  options = [],
  inputProp,
  onInput = () => {},
  ...rest
}) => {
  const { xl, xs } = Grid.useBreakpoint();
  const { Option } = Select;
  const randomKey = Math.floor(Math.random() * 1000);

  switch (questionType) {
    case QuestionType.Text:
      return (
        <Form.Item
          rules={[
            { required: !isOptional, whitespace: !isOptional, message: "" },
            ...rules,
          ]}
          {...rest}
        >
          <Input
            onBlur={(e) => onInput(e.target.value)}
            onPressEnter={(e) => e.preventDefault()}
            value={currentAnswer}
            disabled={isLoading}
            {...inputProp}
          />
        </Form.Item>
      );

    case QuestionType.SingleDropDown:
      return (
        <Form.Item
          rules={[{ required: !isOptional, message: "" }, ...rules]}
          {...rest}
        >
          <Select
            {...selectProps}
            allowClear={isOptional}
            value={currentAnswer}
            disabled={isLoading}
            onSelect={onInput}
            {...inputProp}
          >
            {options.map((option, index) => (
              <Option
                value={option.value}
                key={`${option.label}${index}`}
                disabled={option.disabled}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    case QuestionType.SingleRadio:
      return (
        <Form.Item
          rules={[{ required: !isOptional, message: "" }, ...rules]}
          {...rest}
        >
          <Radio.Group
            disabled={isLoading}
            onChange={(e) => onInput(+e.target.value)}
            value={currentAnswer}
            options={options}
            {...inputProp}
          />
        </Form.Item>
      );

    case QuestionType.Scale:
      return (
        <Form.Item
          rules={[{ required: !isOptional, message: "" }, ...rules]}
          {...rest}
        >
          <Radio.Group
            style={{ width: "100%" }}
            disabled={isLoading}
            value={currentAnswer}
            onChange={(e) => onInput(+e.target.value)}
            {...inputProp}
          >
            {xl ? (
              <Row>
                {options.map((option, index) => (
                  <Col
                    key={`${option.label}${index}`}
                    span={Math.floor(24 / options.length)}
                    style={{ textAlign: "center" }}
                  >
                    <Radio value={option.value} />
                  </Col>
                ))}
              </Row>
            ) : (
              <Space
                wrap
                justify={xs ? "flex-start" : "space-evenly"}
                direction={xs ? "vertical" : "horizontal"}
              >
                {options.map((option, index) => (
                  <Radio
                    value={option.value}
                    key={`${option.label}${index}${randomKey}`}
                  >
                    {option.label}
                  </Radio>
                ))}
              </Space>
            )}
          </Radio.Group>
        </Form.Item>
      );

    default:
      return null;
  }
};

export default QuestionSlot;
