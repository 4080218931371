import { ClientUserDto } from "@api";
import { createContext } from "react";

interface IContext {
  clientUserInfo: ClientUserDto | null;
  lang: string;
  intlMsg: any;
  setClientUserInfo: (id: ClientUserDto | null) => void;
  setLang: (lang: string) => void;
  setIntlMSG: (intlMSg: object) => void;
}
export default createContext<IContext>({
  clientUserInfo: null,
  lang: "en",
  intlMsg: {},
  setClientUserInfo: () => {},
  setLang: () => {},
  setIntlMSG: () => {},
});
