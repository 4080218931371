export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_URL}/login-callback/`,
  response_type: "code",
  scope: "openid profile cal.assessmentplatform.webapp.WebUIAPI",
  silent_redirect_uri: `${process.env.REACT_APP_URL}/silent-callback/`,
  post_logout_redirect_uri: `${process.env.REACT_APP_URL}/logout-callback/`,
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: false,
  monitorSession: false,
  prompt: "login",
};

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_AUTH_URL,
  jwks_uri: `${process.env.REACT_APP_AUTH_URL}/.well-known/openid-configuration/jwks`,
  authorization_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/authorize`,
  token_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/token`,
  userinfo_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/userinfo`,
  end_session_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/endsession`,
  check_session_iframe: `${process.env.REACT_APP_AUTH_URL}/connect/checksession`,
  revocation_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/revocation`,
  introspection_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/introspect`,
};

export enum ROLE {
  Administrator = "Administrator",
  Technical = "Technical",
  Distributor = "Distributor",
  ClientAdministrator = "ClientAdministrator",
  ClientUser = "ClientUser",
}

export enum SECTION {
  Admin = "Admin",
}

export enum ACTION {
  Access = "access",
  List = "list",
  Read = "read",
  Create = "create",
  Edit = "edit",
  Delete = "delete",
  Download = "download",
  Upload = "upload",
  Send = "send",
  Archive = "archive",
}
