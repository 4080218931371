import React from "react"
import { Popover as AntPopover, PopoverProps } from "antd"

const Popover: React.FC<PopoverProps> = ({ children, ...rest }) => {
  return (
    <AntPopover placement="topLeft" trigger="click" {...rest}>
      {children}
    </AntPopover>
  )
}

export default Popover
