import React, { FC } from "react"
import { Table as CustomTable, Grid, TableProps } from "antd"

interface ICustomTable {
  onPageChange?: (params: any) => void
  defaultOrder?: {
    orderBy: string
    isAsc: boolean
  }
}

interface ITable extends TableProps<any>, ICustomTable {}

const Table: FC<ITable> = ({
  onPageChange,
  defaultOrder,
  pagination,
  ...rest
}) => {
  const { xl } = Grid.useBreakpoint()

  const handleChange = (paginate: any, _: any, sorter: any, extra: any) => {
    if (!onPageChange) return

    switch (extra.action) {
      case "paginate":
        onPageChange({
          pageNumber: paginate.current,
          pageSize: paginate.pageSize,
        })
        break
      case "sort":
        if (sorter.order) {
          let orderBy
          if (sorter.columnKey) {
            orderBy = sorter.columnKey
          } else {
            orderBy =
              sorter.field.charAt(0).toUpperCase() + sorter.field.slice(1)
          }
          onPageChange({
            pageNumber: 1,
            orderBy: orderBy,
            isAsc: sorter.order === "ascend",
          })
        } else {
          onPageChange({
            pageNumber: 1,
            orderBy: defaultOrder?.orderBy ?? "Id",
            isAsc: defaultOrder?.isAsc,
          })
        }
        break
      default:
        break
    }
  }

  return (
    <CustomTable
      rowKey="id"
      size={xl ? "middle" : "small"}
      onChange={handleChange}
      scroll={{ x: true }}
      pagination={
        pagination ? { size: xl ? "default" : "small", ...pagination } : false
      }
      {...rest}
    />
  )
}

export default Table
