import { useState } from "react";
import { isBrowser } from "@services/auth";

const useSessionStorage = (key: string, defaultValue: string | object) => {
  const initialValue = isBrowser ? sessionStorage.getItem(key) : null;
  const [persistedValue, setPersistedValue] = useState(
    initialValue ? JSON.parse(initialValue) : defaultValue
  );

  const setValue = (newValue: any) => {
    setPersistedValue(newValue);
    sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  return [persistedValue, setValue];
};

export default useSessionStorage;
