import React, { FC, ReactNode, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import { IntlProvider } from "react-intl";
import "moment/locale/zh-tw";
import { mgr, logout } from "@services/auth";
import { Log } from "oidc-client";
import useSessionStorage from "./useSessionStorage";
import Store from "./store";
import { ClientUserDto, ClientUserInternationalizationsClient } from "@api";

// temp
const adminPageIntelMsg = {
  // commented out not in used
  // admin: "Admin",
  // action: "Action",
  // question_relationships: "Question Relationships",
  // question_choicecontents: "Question Choice Contents",
  // home: "Home",
  // desc: "Descriptions",
  // en: "English",
  // "zh-Hans": "Simplified Chinese",
  // "zh-Hant": "Traditional Chinese",
  // QuestionType_0: "Scale",
  // QuestionType_1: "Text",
  // QuestionType_2: "Single Radio",
  // QuestionType_3: "Single Drop Down",
  // scoring_key: "Scoring Key",
  // create_new_record: "Create New Record",
  // report_template_configs: "Report Template Configs",
  // key: "Key",
  // shape_name: "Shape Name",
  // generate_setting: "Generate Setting",
  // select_generate_setting: "Select a generate setting",
  // configuration: "Configuration",
  // question_content: "Question Content",
  // form_desc: "Form Descriptions",
  // select_assessment_template: "Select a assessment template",
  // client_group_assessment_templates: "Client Group Assessment Templates",
  // client_group_report_generation_settings:
  //   "Client Group Report Generation Settings",
  // technical_admin: "Technical Admin",
  // create_new_technical_admin: "Create New Technical Admin",
  // download_administrators: "Download Administrators",
  // upload_administrators: "Upload Administrators",
  // missing_quota: "Please enter quota",
  // new_password: "New Password",
  // old_password: "Old Password",
  // can_customize_preferred_language: "Customize Language",
  // can_customize_email: "Customize Email",
  // can_customize_password: "Customize Password",
  // can_customize_fullname: "Customize FullName",
  // customize_fullname_label: "FullName Label",
  // email_recipient: "Email Recipient",
  // send_group_email: "Send Group Email",
  // all: "All",
  // activate: "Activate",
  // group_name: "Group name",
  // billing_title_report_detail: "Report Billing Detail",
  // billing_title_assessment_detail: "Assessment Billing Detail",
  // client_user_migrate_destination: "Destination",
  // missing_content: "Please input content!",
  ReportGroupReportTemplateStaticTypeLabel: "Type",
  ReportGroupReportTemplateStaticMissingTypeRule: "Please select a type",
};

interface StoreProps {
  children: ReactNode;
}

const StoreProvider: FC<StoreProps> = ({ children }) => {
  const [clientUserInfo, setClientUserInfo] = useState(
    null as ClientUserDto | null
  );
  const [lang, setLang] = useSessionStorage("lang", "en");
  const [intlMsg, setIntlMSG] = useSessionStorage("i18NMsg", {
    SigningIn: "Logging in...",
    loggingOut: "Logging out...",
  });

  Log.logger = console;

  mgr?.events.addAccessTokenExpired(() => {
    try {
      mgr?.signinSilent();
    } catch (err) {
      logout();
    }
  });

  mgr?.events.addSilentRenewError(() => {
    console.log("slient Renew failed");
    logout();
  });

  useEffect(() => {
    const fetchSetIntlMsg = async () => {
      const intlClient = new ClientUserInternationalizationsClient();

      const newIntlMsg = await intlClient.get(lang ?? "en").catch((err) => {
        console.log("get intl msg failed", err);
        return {};
      });
      setIntlMSG({
        ...newIntlMsg,
        ...adminPageIntelMsg,
      });
    };

    fetchSetIntlMsg();
  }, [lang]);

  return (
    <Store.Provider
      value={{
        clientUserInfo: clientUserInfo,
        intlMsg: intlMsg,
        lang: lang,
        setClientUserInfo: setClientUserInfo,
        setLang: setLang,
        setIntlMSG: setIntlMSG,
      }}
    >
      <IntlProvider
        locale={lang}
        key={lang}
        defaultLocale={"en"}
        messages={intlMsg}
      >
        <ConfigProvider dropdownMatchSelectWidth={false} locale={enUS}>
          {children}
        </ConfigProvider>
      </IntlProvider>
    </Store.Provider>
  );
};

export default StoreProvider;
