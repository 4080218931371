import React from "react";
import { Space as CustomSpace, SpaceProps } from "antd";

interface ICustomProps {
  justify?:
    | "flex-end"
    | "center"
    | "flex-start"
    | "space-evenly"
    | "space-between";
}

interface ISpace extends SpaceProps, ICustomProps {}

const Space: React.FC<ISpace> = ({
  justify = "flex-end",
  children,
  ...rest
}) => (
  <CustomSpace style={{ width: "100%", justifyContent: justify }} {...rest}>
    {children}
  </CustomSpace>
);
export default Space;
