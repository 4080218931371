import { AccessControl } from "role-acl";
import { ROLE } from "./authConst";

import { default as adminPolicy } from "../acl/Administrator.json";
import { default as technicalPolicy } from "../acl/Technical.json";
import { default as distributorPolicy } from "../acl/Distributor.json";
import { default as clientAdminPolicy } from "../acl/ClientAdministrator.json";

const policy = {
  [ROLE.Administrator]: {
    grants: adminPolicy,
  },
  [ROLE.Technical]: {
    grants: technicalPolicy,
  },
  [ROLE.Distributor]: {
    grants: distributorPolicy,
  },
  [ROLE.ClientAdministrator]: {
    grants: clientAdminPolicy,
  },
  [ROLE.ClientUser]: {},
};

const acl = new AccessControl(policy);

export default acl;
