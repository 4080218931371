import React, { FC } from "react"
import { Upload as CustomUpload, UploadProps } from "antd"



const Upload = (props: UploadProps) => {
	return <CustomUpload {...props} showUploadList={false} ></CustomUpload>
};

export default Upload
