import {
  IDENTITY_CONFIG,
  METADATA_OIDC,
  ROLE,
  SECTION,
  ACTION,
} from "./authConst";
import { UserManager } from "oidc-client";
import acl from "./acl";
import { Permission } from "role-acl";

export const isBrowser = typeof window !== "undefined";

export const mgr = isBrowser
  ? new UserManager({
      ...IDENTITY_CONFIG,
      metadata: METADATA_OIDC,
    })
  : null;

export const getUser = () => {
  const user = isBrowser
    ? sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.CLIENT_ID}`
      )
    : null;

  return user;
};

export const getProfile = () => {
  const userJson = getUser();
  return userJson ? JSON.parse(userJson).profile : null;
};

export const getIsRole = (role: ROLE) => {
  return getProfile()?.role === role;
};

export const getIsAdmin = () => {
  return getIsRole(ROLE.Administrator);
};
export const getIsClientAdmin = () => {
  return getIsRole(ROLE.ClientAdministrator);
};
export const getIsDistributor = () => {
  return getIsRole(ROLE.Distributor);
};
export const getIsTechnical = () => {
  return getIsRole(ROLE.Technical);
};

export const can = (
  action: ACTION,
  section: SECTION,
  page: string | undefined = undefined,
  subpage: string | undefined = undefined,
  ...contexts: (string | undefined)[]
) => {
  const resourceKey = buildResourceKey(section, page, subpage, ...contexts);
  const role = getProfile()?.role ?? ROLE.ClientUser;
  const permission = acl
    .can(role)
    .execute(action)
    .sync()
    .on(resourceKey) as Permission;
  // console.log(
  //   "Can",
  //   role,
  //   action,
  //   resourceKey,
  //   "?",
  //   permission.granted ? "Yes" : "No"
  // );
  return permission.granted;
};

export const cannot = (
  action: ACTION,
  section: SECTION,
  page: string | undefined = undefined,
  subpage: string | undefined = undefined,
  ...contexts: (string | undefined)[]
) => {
  return !can(action, section, page, subpage, ...contexts);
};

export const buildResourceKey = (
  section: SECTION,
  page: string | undefined = undefined,
  subpage: string | undefined = undefined,
  ...contexts: (string | undefined)[]
) => {
  return [section, page, subpage, ...contexts]
    .filter((value) => !!value)
    .join("/");
};

export class SectionAuth {
  section: SECTION;
  constructor(section: SECTION) {
    this.section = section;
  }
  can(
    action: ACTION,
    page: string | undefined = undefined,
    subpage: string | undefined = undefined,
    ...contexts: (string | undefined)[]
  ) {
    return (
      can(ACTION.Access, this.section) &&
      can(action, this.section, page, subpage, ...contexts)
    );
  }
  cannot(
    action: ACTION,
    page: string | undefined = undefined,
    subpage: string | undefined = undefined,
    ...contexts: (string | undefined)[]
  ) {
    return !this.can(action, page, subpage, ...contexts);
  }
}
export class PageAuth extends SectionAuth {
  page: string | undefined;
  constructor(section: SECTION, page: string | undefined) {
    super(section);
    this.page = page;
  }
  can(
    action: ACTION,
    subpage: string | undefined = undefined,
    ...contexts: (string | undefined)[]
  ) {
    return (
      super.can(ACTION.Access, this.page) &&
      super.can(action, this.page, subpage, ...contexts)
    );
  }
  cannot(
    action: ACTION,
    subpage: string | undefined = undefined,
    ...contexts: (string | undefined)[]
  ) {
    return !this.can(action, subpage, ...contexts);
  }
}

export class SubpageAuth extends PageAuth {
  subpage: string | undefined;
  constructor(
    section: SECTION,
    page: string | undefined,
    subpage: string | undefined
  ) {
    super(section, page);
    this.subpage = subpage;
  }
  can(action: ACTION, ...contexts: (string | undefined)[]) {
    return (
      super.can(ACTION.Access, this.subpage) &&
      super.can(action, this.subpage, ...contexts)
    );
  }
  cannot(action: ACTION, ...contexts: (string | undefined)[]) {
    return !this.can(action, ...contexts);
  }
}

export const logoutCallback = async () => {
  return await mgr?.signoutRedirectCallback();
};

export const logout = () => {
  mgr?.signoutRedirect();
};

export const logoutRedirect = () => {
  mgr?.signoutRedirectCallback();
};

export const isLoggedIn = (path: string) => {
  if (
    [
      "/logout-callback/",
      "/login-callback/",
      "/silent-callback/",
      "/register/:passphrase",
    ].includes(path)
  )
    return true;

  const storage = getUser();
  const user = storage ? JSON.parse(storage) : null;
  return !!user && !!user.access_token;
};

export const signinRedirect = (url: string) => {
  mgr?.signinRedirect({ state: url });
};

export const login = () => {
  if (isBrowser) {
    signinRedirect(window.location.href);
  }
};

export const loginCallback = async () => {
  const result = await mgr?.signinRedirectCallback();
  return result;
};

export const loginSilentCallback = () => {
  try {
    mgr?.signinSilentCallback();
  } catch (err) {
    logout();
  }
};

export const clearStale = () => {
  mgr?.clearStaleState();
};

export { ACTION, ROLE, SECTION };
